.veryLightScheme {
  background-color: white;
  color: #45062e;
}
.lightScheme {
  background-color: #f8f9fa;
  color: #45062e;
}
.middleScheme {
  background-color: #e5a4cb;
  color: #45062e;
}
.darkScheme {
  background-color: #45062e;
  color: white;
}
.contentContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.contentContainer .item {
  margin-bottom: 40px;
}
.contentContainer h2,
.contentContainer h3 {
  margin-bottom: 20px;
}
.contentContainer h4 {
  margin-top: 40px;
  margin-bottom: 20px;
}
.navbar-light .navbar-nav .nav-link {
  color: #45062e;
  opacity: 0.7;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #45062e;
  opacity: 1;
}
.bg-light {
  background-color: #e4e7eb !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.mainContainer {
  padding-bottom: 40px;
}
.homeContainer .slogan {
  padding-top: 26px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 1.5rem;
}
.homeContainer .slogan .separator {
  padding-left: 10px;
  padding-right: 10px;
}
.galleryContainer .slogan {
  padding-top: 26px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 1.5rem;
}
.galleryContainer .col-lg-6 {
  padding: 0;
}
.galleryContainer img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}
.donationForm {
  margin-top: 40px;
}
.donationForm .form-group {
  position: relative;
  height: 30px;
}
.donationForm input.form-control {
  position: absolute;
  top: 0;
  left: 0;
}
.donationForm label.currency-label {
  position: absolute;
  font-size: 0.8em;
  top: 30%;
  left: 5px;
  text-transform: uppercase;
  color: #495057;
}
.donationForm .privacyText {
  margin-top: 10px;
  font-size: 0.8em;
}
.fundraiserContainer .container {
  clear: all;
  padding: 40px;
}
.fundraiserContainer .container.introduction {
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .fundraiserContainer .container.description .text,
  .fundraiserContainer .container.whoWeAre .text {
    column-count: 2;
  }
}
.fundraiserContainer .container.description h4,
.fundraiserContainer .container.whoWeAre h4 {
  margin-bottom: 20px;
}
.fundraiserContainer .container.descriptionImage img {
  width: 100%;
  height: auto;
}
.fundraiserContainer .container.donated h1 {
  font-size: 4em;
}
.fundraiserContainer .container.actions a {
  display: block;
  text-align: center;
  color: #7f055f;
  text-decoration: underline;
}
.fundraiserContainer .container.actions p {
  margin-top: 10px;
}
.fundraiserContainer .container.actions .clipboard {
  cursor: pointer;
}
.fundraiserContainer .carouselImg {
  height: 600px;
  width: 600px;
  object-fit: contain;
}
.verticalCenterSpacer {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.verticalCenter {
  vertical-align: middle;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 80px;
  text-align: center;
  text-decoration: none;
}
.fa:hover {
  opacity: 0.7;
  color: white;
}
.fa-facebook {
  background: #3B5998;
  color: white;
}
.fa-whatsapp {
  background: #25D366;
  color: white;
}
a:hover {
  text-decoration: none;
}
.invisibleLinkText {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
.mainButton {
  background-color: #7f055f;
  border-color: #45062e;
}
.mainButton:hover {
  background-color: #4e033a;
  border-color: #45062e;
}
